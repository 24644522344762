:root {
    --swiper-navigation-color: #999999;
    --swiper-pagination-color: #999999;
}
.swiper-container{
    .swiper-wrapper{
        .swiper-slide{
            img{
                width: 100%;
                height: auto;
            }
            .swiper-slide-inner{
                position: absolute;
                top: 50%;
                left: 50%;
                transform: translate(-50%, -50%);
                width: 100%;
                .swiper-slide_title{
                    color: $white;
                    font-size: 5.4rem;
                    letter-spacing: .5rem;
                    @include media-breakpoint-down(md) {
                        font-size: 3.2rem;
                    }
                    @include media-breakpoint-down(sm) {
                        font-size: 1.8rem;
                    }
                }
                
                .swiper-slide_subtitle{
                    color: $white;
                    font-size: 1.8rem;
                    letter-spacing: .5rem;
                    margin-bottom: 5rem;
                    @include media-breakpoint-down(md) {
                        font-size: 1.2rem;
                        margin-bottom: 2rem;
                    }
                    @include media-breakpoint-down(sm) {
                        font-size: 1rem;
                        margin-bottom: 1rem;
                    }
                }
                .swiper-slide_button{
                    a{
                        display: inline-block;
                        font-size: 1rem;
                        line-height: 1rem;
                        background-color: $gray-900;
                        color: $white;
                        padding: .8rem 2.4rem .7rem;
                        border-radius: 200px;
                        opacity: .9;
                        @include media-breakpoint-down(sm) {
                            font-size: .8rem;
                        }
                    }
                }
            }
        }
    }
}
.swiper-container-horizontal > .swiper-pagination-bullets.swiper-home-items-pagination{
    bottom: -30px;
}

