#header {
    margin-bottom: 4rem;
}

.subNavi {
    ul {
        li {
            margin-left: 10px;
            padding-top: 10px;
            a {
            }
        }
    }
}

.globalNav {
    /* メニュー全体のスタイル */
    width: 100%;
    position: relative;
    text-align: center;
    z-index: 99999;
    .globalNav_parentMenu {
        display: flex;
        justify-content: center;
        a {
            color: $main-color;
            display: block;
            padding: 5px 10px;
        }
    }
    .globalNav_parentMenuItem {
        &:hover {
            & > a {
                // background: $sub-color;
                color: $light-color;
            }
        }
        & > a {
            display: flex;
        }
    }
}

@include media-breakpoint-down(md) {
    #header {
        margin-right: 100px;
    }
    .opennavi { // navボタン
        position: fixed;
        top: 0px;
        right: 0;
        /* 		background: white; */
        z-index: 100000;
        padding: 10px;
        width: 50px;
        height: 34px;
        cursor: pointer;
        margin: 10px;
        span {
            position: absolute;
            left: 0;
            width: 100%;
            height: 5px;
            background-color: #c72e14;
            border-radius: 4px;
            display: inline-block;
            transition: all 0.2s;
            box-sizing: border-box;
            &:nth-of-type(1) {
                top: 0;
            }
            &:nth-of-type(2) {
                top: 15px;
            }
            &:nth-of-type(3) {
                bottom: 0;
            }
        }
        &.active {
            span {
                &:nth-of-type(1) {
                    transform: translateY(20px) rotate(-45deg);
                    top: -5px;
                }
                &:nth-of-type(2) {
                    opacity: 0;
                }
                &:nth-of-type(3) {
                    transform: translateY(-20px) rotate(45deg);
                    bottom: -5px;
                }
            }
        }
    }

    .globalNav {
        position: fixed;
        top: 0;
        left: 0;
        display: none;
        height: 100vh;
        background: white;
        .globalNav_parentMenu{
            display: block;
            padding-top: 100px;
        }
        ul {
            flex-direction: column;
            
            li {
                // height: 10vh;
                padding-bottom: 1rem;
                margin-bottom: 1rem;
                border-bottom: 1px dotted #c72e14;
                a {
                    // height: 100%;
                }
            }
        }
    }
    .is_navopen .globalNav{
        display: block;
    }
}
