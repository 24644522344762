#main{

  .catch{
    font-size: 3rem;
    line-height: 1.46;
    letter-spacing: -1px;
    font-weight: normal;
  }

  #main_image{
    margin-bottom: 42px;
  }

  #about{
    padding-bottom: 120px;
    p{
      // font-weight: 600;
      margin-bottom: 1em;
      position: relative;
      &:last-of-type{
        font-size: 1.3rem;
        margin-bottom: 52px;
      }
    }

    .img{
      margin-bottom: 45px;
    }

    table{
      // font-weight: 600;

      th,
      td{
        @include media-breakpoint-down(sm){
          display: block;
        }
      }

      th{
        font-weight: normal;
      }

      tr:not(:last-of-type){
        td{
          @include media-breakpoint-down(sm){
            margin-bottom: 1em;
          }
        }
      }
    }

    .table_1{
      margin-bottom: 40px;
      th{
        width: 10.5em;
        vertical-align: top;
      }
    }

    .table_2{
      th{
        width: 6.5em;
      }
    }
    
    .m-bg_img{
      img{
        top: -9.5rem;
        left: 3rem;
      }
    }
  }

  #member{
    position: relative;
    @include media-breakpoint-down(md) {
      #member{text-align: center;}
    }
    
    .m-bg_img{
      img{
        right: 7rem;
      }
    }
    .js-modal_link{
      cursor: pointer;
    }
    .position,
    .name{
      font-weight: 600;
      text-align: center;
    }
    .position,
    .text{
      font-size: 1.4rem;
      text-align: center;
    }
    .name{
      font-size: 1.8rem;
      text-align: center;
    }
  }

  #news{
    padding-top: 270px;
    position: relative;
    background: url(../images/bg_img_03.png) no-repeat center top;
    background-size: contain;
    @include media-breakpoint-down(md) {
      #news{padding-top: 150px;}
    }
    
    ul{
      padding-left: 0;
      border-bottom:1px dotted #c72e14;
      li{
        padding: 20px 0;
        border-top:1px dotted #c72e14;
        a{
          span{
            float: right;
            margin-right: 20px;
          }
        }
      }
    }

    .m-bg_img{
      img{
        right: 7rem;
      }
    }
    .position,
    .name{
      font-weight: 600;
      text-align: center;
    }
    .position,
    .text{
      font-size: 1.4rem;
      text-align: center;
    }
    .name{
      font-size: 1.8rem;
    }
  }
  #npo{
    .position,
    .name{
      font-weight: 600;
      text-align: center;
    }
    .position,
    .text{
      font-size: 1.4rem;
      // width: 375px;
      // text-align: center;
    }
    .name{
      font-size: 1.8rem;
    }
    .section_title{
      font-size: 1.8rem;
    }
    .m-modal{
      .position,
      .text{
        width: 375px;
      }
    }
  }

  #storia{
    .kome{
      font-size: 12px;
    }
  }
}
