/* グリッドコンテナー */
.parent {
    display: grid;
    grid-template-columns: 1fr 1fr 1fr 1fr;
    grid-template-rows: 1fr 1fr 1fr;
    grid-gap: 12px;
    margin-bottom: 12px;
    // height: 490px;
}

/* グリッドアイテム */
.child1 {
    grid-row: 1/3;
    grid-column: 1/3;
}
.child2 {
    grid-row: 3/4;
    grid-column: 1/2;
}
.child3 {
    grid-row: 3/4;
    grid-column: 2/3;
}
.child4 {
    grid-row: 1/2;
    grid-column: 3/4;
}
.child5 {
    grid-row: 1/2;
    grid-column: 4/5;
}
.child6 {
    grid-row: 2/4;
    grid-column: 3/5;
}

.grid-link {
    display: block;
    width: 100%;
    height: 100%;
    position: relative;
    .grid-over {
        position: absolute;
        display: block;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
        background-color: rgba(0, 0, 0, 0.8);
        opacity: 0;
        transition: opacity ease 0.2s;
        text-align: center;
        .grid-over-text {
            display: block;
            position: absolute;
            top: 50%;
            left: 50%;
            transform: translate(-50%, -50%);
            color: #fff;
        }
    }
    &:hover {
        .grid-over {
            opacity: 1;
        }
    }
}

.js-modal_link:hover {
    img {
        opacity: 0.5;
        transition: opacity ease 0.2s;
    }
}

.m-bg_img {
    position: relative;
    z-index: -1;
    img {
        position: absolute;
    }
}

.m-modal {
    display: none;
    width: 100%;
    height: 100%;
    z-index: 999999;
    position: fixed;
    top: 0;
    left: 0;

    &:before {
        display: block;
        content: "";
        background-color: rgba(255, 255, 255, 0.9);
        width: 100%;
        height: 100%;
        position: absolute;
        top: 0;
        left: 0;
    }

    .close_btn {
        cursor: pointer;
        display: block;
        width: 60px;
        height: 75px;
        position: absolute;
        // top: 36px;
        // right: 53px;
        right: 20px;
        top: 20px;
        z-index: 99999;

        &:before,
        &:after {
            display: block;
            content: "";
            width: 80px;
            height: 1px;
            background-color: $main-color;
            position: absolute;
            top: 28px;
            left: -11px;
        }

        &:before {
            transform: rotate(45deg);
        }
        &:after {
            transform: rotate(-45deg);
        }

        .btn_text {
            // font-size: 18px;
            // position: absolute;
            // top: 49px;
            // left: 6px;
            font-size: 18px;
            left: 50%;
            position: absolute;
            top: 65px;
            transform: translate(-50%);
        }
    }

    .inner {
        display: inline-block;
        max-width: calc(100% - 40px);
        max-height: calc(100vh - 72px);
        width: fit-content;
        // background-color: #fff;
        padding: 20px;
        z-index: 9999;
        position: absolute;
        top: 50%;
        left: 50%;
        transform: translate(-50%, -50%);
        overflow-y: auto;
    }
}

.m-hover_img{
    transition: .2s;
    &:hover{
        opacity: .6;
    }
}