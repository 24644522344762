html {
    font-size: 10px;
    height: -webkit-fill-available;
}

.bg-main {
    background-color: $main-color;
}

body {
    font-family: "Noto Sans JP", sans-serif;
    font-size: 1.6rem;
    line-height: 2;
    color: $main-color;
    min-height: 100vh;
    /* mobile viewport bug fix */
    min-height: -webkit-fill-available;
    &.is_navopen{
        height: 100vh;
        overflow: hidden;
        position: fixed;
        top: 0;
    }
}

img {
    max-width: 100%;
}

a {
    text-decoration: none;
    color: $main-color;
    &:hover{
        color: $light-color;
    }
}

a.more,
a.cf {
    color: #42938f;
    cursor: pointer;
}
.marker {
    background-color: #fad740;
}

.iphone {
    display: none;
}
@include media-breakpoint-down(md) {
    .iphone {
        display: block;
    }
}
